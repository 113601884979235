import React from 'react';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { SUPPORTED_CURRENCY, SUPPORTED_LANGUAGE } from '../utils/constant';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCurrency, getLanguage } from '../utils/utility';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 10,
    margin: 20,
    borderRadius: 0,
    maxWidth: 420,
    width: '100%',
  },
};

function LocalSwitchModal(props) {
  const { t, config, menu } = props;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [currencyId, setCurrnecyId] = useState(config.currentCurrency?.id);
  const [languageId, setLanguageId] = useState(config.currentLanguage?.id);

  const openModal = () => {
    setCurrnecyId(config.currentCurrency.id);
    setLanguageId(config.currentLanguage.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleApply = () => {
    localStorage.setItem('menu_language_' + menu.id, JSON.stringify(getLanguage(languageId)));
    localStorage.setItem('menu_currency_' + menu.id, JSON.stringify(getCurrency(currencyId)));
    localStorage.setItem('menu_i18nextLng', getLanguage(languageId).code);

    closeModal();
    window.location.reload();
  };

  const handleLanguageChange = (l) => {
    setLanguageId(l.id);
  };

  const handleCurrencyChange = (c) => {
    setCurrnecyId(c.id);
  };

  const menuSupportedLang = menu.supported_lang?.split(',').map((e) => parseInt(e));
  const menuSupportedCurrency = menu.supported_currency?.split(',').map((e) => parseInt(e));

  const renderButton = () => {
    if (props.position === 'footer') {
      return <img src="/assets/icon/language-solid.svg" onClick={openModal} alt="" />;
    } else {
      return (
        <button className="local-switch" onClick={openModal}>
          {config.currentLanguage?.title} ({config.currentCurrency?.symbol})
        </button>
      );
    }
  };

  return (
    <>
      {renderButton()}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
        <div className="local-switch-modal">
          <img className="close" src="/assets/icon/close.svg" onClick={closeModal} alt={t('Close')} />
          <div>
            <p>{t('Language')}:</p>
            <ul>
              {SUPPORTED_LANGUAGE.filter((l) => menuSupportedLang?.indexOf(l.id) !== -1).map((l) => {
                return (
                  <li key={l.id} className={l.id === languageId ? 'active' : ''} onClick={() => handleLanguageChange(l)}>
                    {l.title}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <p>{t('Currency')}:</p>
            <ul>
              {SUPPORTED_CURRENCY.filter((c) => menuSupportedCurrency?.indexOf(c.id) !== -1).map((c) => {
                return (
                  <li key={c.id} className={c.id === currencyId ? 'active' : ''} onClick={() => handleCurrencyChange(c)}>
                    {c.title} ({c.symbol})
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="actions">
            <div className="button-apply" onClick={handleApply}>
              {t('Apply')}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LocalSwitchModal));
