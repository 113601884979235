import React from 'react';
import CategoryImage from './../../components/CategoryImage';

function GridView({ mainCategories, onCategorySelected }) {
  return (
    <div className="category-list grid-view">
      {mainCategories &&
        mainCategories.map((category) => {
          return (
            <div
              role="listitem"
              key={category.id}
              category-id={category.id}
              className="category"
              onClick={() => onCategorySelected(category.id)}>
              <CategoryImage category={category} title={category.name} />

              <div className="category-details">
                <h3 className="title">{category.name}</h3>
                {category.description && <p className="description">{category.description}</p>}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default GridView;
