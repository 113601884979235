import React from 'react';
import useMenu from '../hooks/useMenu';

function MenuSocialLink() {
  const { facebookURL, instagramURL, phone } = useMenu();

  return (
    <>
      <div className="social-links">
        {facebookURL && (
          <a target="_blank" href={facebookURL} rel="noopener noreferrer">
            <img src="/assets/icon/facebook.png" />
          </a>
        )}
        {instagramURL && (
          <a target="_blank" href={instagramURL} rel="noopener noreferrer">
            <img src="/assets/icon/instagram.png" alt="" />
          </a>
        )}
        {phone && (
          <a target="_blank" href={'tel:' + phone} rel="noopener noreferrer">
            <img src="/assets/icon/phone.png" alt="" />
          </a>
        )}
      </div>
    </>
  );
}

export default MenuSocialLink;
