import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UtilityService from '../services/utility';

function useProduct() {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const { productId: selectedProductId } = useParams();

  const product = menu?.products?.find((p) => parseInt(p.id) == parseInt(selectedProductId));

  let availableItems = product?.items;
  const dineInOrTakeaway = 'DINE_IN'; //props.config.dineInOrTakeaway;
  const dineInExtraCostEnabled = menu?.settings?.DINE_IN_EXTRA_COST_ENABLED === 'true';

  if (dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
    availableItems = availableItems?.filter((p) => UtilityService.isAllowToDineIn(p));
  }
  const getProductById = (productId) => {
    return menu?.products?.find((p) => parseInt(p.id) == parseInt(productId));
  };

  const getProductByIds = (productIds) => {
    return menu?.products?.filter((p) => productIds.indexOf(p.id) != -1);
  };

  return {
    ...product,
    availableItems,
    getProductById: getProductById,
    getProductByIds: getProductByIds,
  };
}

export default useProduct;
