import React from 'react';

function Loading() {
  return (
    <div className="loading">
      <p>GET MENU</p>
      <img alt="" src="/assets/loading.svg" />
    </div>
  );
}

export default Loading;
