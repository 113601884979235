import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';
import LocalSwitchModal from '../LocalSwitchModal';
import ShareModal from './../ShareModal';

function Header(props) {
  const { logoURL, backgroundURL, shareLinkEnabled, activeOrdersEnabled } = useMenu();
  const { uuid } = useQRCode();
  const { t } = useTranslation();

  const activeOrdersUrl = '/m/' + uuid + '/orders';

  return (
    <div className="top-header">
      <div className="top-actions">
        <div className="left">
          {props.showBackButton && (
            <div className="action back">
              <Link to={props.backURL}>
                <img src="/assets/icon/primary-arrow.svg" alt="" />
              </Link>
            </div>
          )}
          {props.showShareButton && shareLinkEnabled && (
            <div className="action share">
              <ShareModal />
            </div>
          )}
        </div>
        <div className="right">
          {activeOrdersEnabled && (
            <Link to={activeOrdersUrl} className="active-orders-button">
              {t('Orders')}
              <img src="/assets/icon/cart-shopping-solid.svg" alt="" />
            </Link>
          )}
          {props.showLocalSwitch && <LocalSwitchModal />}
        </div>
      </div>
      {props.showBusinessDetails && (
        <>
          <div className="image-wrapper">
            <img alt="" src={backgroundURL} className="image" />
          </div>
          <div className="container">
            <div className="business-image">
              <img alt="" src={logoURL} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Header;
