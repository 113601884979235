import React, { useState } from 'react';
import useMenu from '../../hooks/useMenu';

function GroupNavigation({ onChange }) {
  const { selectedCategory } = useMenu();
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const handleGroupSelect = (groupId) => {
    setSelectedGroupId(groupId);
    onChange(groupId);
  };

  return (
    <div className="group-navigation">
      <div className="group-list">
        {selectedCategory &&
          selectedCategory.groups
            ?.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
            .map((group) => {
              const active = selectedGroupId === group.id;
              return (
                <div className={'group ' + (active ? 'active' : '')} key={group.id} onClick={() => handleGroupSelect(group.id)}>
                  {group.name}
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default GroupNavigation;
