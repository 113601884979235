import { useSelector, useDispatch } from 'react-redux';

function useQRCode() {
  // Access the Redux state
  const qrcode = useSelector((state) => state.qrcode);

  return { uuid: qrcode.uuid };
}

export default useQRCode;
