import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';

import GridView from './GridView';
import ListView from './ListView';
import useDesign from '../../hooks/useDesign';

function CategoryList() {
  const { uuid } = useQRCode();
  const { mainCategories } = useMenu();
  const { categoryListType } = useDesign();

  const navigate = useNavigate();

  const navigateToCategory = (categoryId) => {
    navigate(`/m/${uuid}/category/${categoryId}`);
  };

  const renderCategoryList = () => {
    if (categoryListType === 'GridView') {
      return <GridView mainCategories={mainCategories} onCategorySelected={navigateToCategory} />;
    } else {
      return <ListView mainCategories={mainCategories} onCategorySelected={navigateToCategory} />;
    }
  };

  return <>{mainCategories && mainCategories.length > 0 && <div className="section main-category">{renderCategoryList()}</div>}</>;
}

export default CategoryList;
