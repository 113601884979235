import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useMenu from '../../hooks/useMenu';
import CategoryNavigation from './../../components/CategoryNavigation';
import Group from './Group';
import GroupNavigation from './../../components/GroupNavigation';

const ProductListPage = () => {
  const { showLocalSwitch, selectedCategory } = useMenu();
  const [selectedGroup, setSelectedGroup] = useState(null);

  const groupList = selectedCategory?.groups.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));

  useEffect(() => {
    if (groupList && groupList.length > 0) {
      setSelectedGroup(groupList[0]);
    }
  }, []);

  const hanleGroupChanged = (groupId) => {
    const g = groupList.find((group) => groupId === group.id);
    setSelectedGroup(g);
  };

  return (
    <div className="two-page-layout product-list-page main-card masteck-ananas-template">
      <Header showBackButton={false} showShareButton={true} showLocalSwitch={showLocalSwitch} showBusinessDetails={true} />

      <CategoryNavigation />

      <div style={{ width: 220, border: '1px solid #FFFFFF', margin: '10px auto' }}></div>

      <GroupNavigation onChange={hanleGroupChanged} />

      <div style={{ padding: 30 }}>{selectedGroup && <Group key={selectedGroup.id} group={selectedGroup} />}</div>

      <Footer />
    </div>
  );
};

export default ProductListPage;
