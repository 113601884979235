import { useSelector } from 'react-redux';
import { MEDIA_BASE_URL } from '../utils/constant';
import { useParams } from 'react-router-dom';
import useLocalization from './useLocalization';

function useMenu() {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const { categoryId: selectedCategoryId } = useParams();
  const { currentCurrency } = useLocalization();

  const diningOptionsModalEnabled = menu?.settings?.DINE_IN_TAKEAWAY_MODAL_ENABLED === 'true';
  const footerNavigationEnabled = menu?.settings?.FOOTER_NAVIGATION_ENABLED === 'true';
  const socialLinkEnabled = menu?.settings?.SOCIAL_LINK_ENABLED === 'true';
  const sortEnabled = menu?.settings?.SORT_ENABLED === 'true';
  const layoutSwitchEnabled = menu?.settings?.LAYOUT_SWITCH_ENABLED === 'true';
  const displaySelectedCategoryNameEnabled = menu?.settings?.DISPLATY_SELECTED_CATEGORY_NAME === 'true';
  const categoryNavigationType = menu?.settings?.CATEGORY_NAVIGATION_TYPE || 'TEXT';
  const dineInExtraCostEnabled = menu?.settings?.DINE_IN_EXTRA_COST_ENABLED === 'true';

  const mainCategoryListVersion = menu?.settings?.MAIN_CATEGORY_LIST_VERSION === 'true';
  const showMainCategoryTitle = menu?.settings?.SHOW_MAIN_CATEGORY_TTILE === 'true';
  const showBrowseAllMenuButton = menu?.settings?.SHOW_BROWSE_ALL_MENU_BUTTON === 'true';
  const shareLinkEnabled = menu?.settings?.SHARE_LINK_ENABLED === 'true';
  const activeOrdersEnabled = menu?.settings?.ACTIVE_ORDERS_ENABLED === 'true';
  const reviewEnabled = menu?.settings?.REVIEW_ENABLED === 'true';
  const showRangePrice = menu?.settings?.SHOW_RANGE_PRICE === 'true';

  let productListVersion = 1;
  if (menu && menu.settings) {
    productListVersion = parseInt(menu.settings.PLP_VERSION || 1);
  }

  const activeCategories = menu?.categories
    ?.filter((c) => {
      return c.name !== null && c.name !== '' && parseInt(c.hidden_category) !== 1;
    })
    .sort((a, b) => a.sequence - b.sequence);

  const mainCategories = menu?.categories
    ?.filter((c) => parseInt(c.main_category) === 1 && parseInt(c.hidden_category) !== 1)
    .sort((a, b) => a.sequence - b.sequence);

  let selectedCategory = menu?.categories?.find((cat) => cat.id == selectedCategoryId);

  if (!selectedCategory && activeCategories) {
    selectedCategory = activeCategories[0];
  }

  const getProductsInfoByIds = (ids) => {
    if (!ids) {
      return [];
    }
    return menu?.products
      ?.filter((p) => ids.indexOf(p.id) !== -1)
      .map((p) => {
        return {
          id: p.id,
          name: p.name,
          description: p.description,
          partnumber: p.partnumber,
          price: p['price_' + currentCurrency.id],
          thumbnail: p.media?.[0]?.path,
        };
      });
  };

  return {
    ...menu,
    diningOptionsModalEnabled,
    footerNavigationEnabled,
    showLocalSwitch: resolveShowLocalSwitch(menu.supported_lang, menu.supported_currency),
    productListVersion,
    socialLinkEnabled,
    sortEnabled,
    layoutSwitchEnabled,
    logoURL: resolveMenuLogo(menu.logo),
    faviconURL: resolveMenuFavicon(menu.favicon),
    backgroundURL: resolveMenuBackground(menu.background),
    activeCategories,
    mainCategories,
    selectedCategory: selectedCategory,
    displaySelectedCategoryNameEnabled,
    categoryNavigationType,
    showMainCategoryTitle,
    mainCategoryListVersion,
    showBrowseAllMenuButton,
    dineInExtraCostEnabled,
    shareLinkEnabled,
    activeOrdersEnabled,
    reviewEnabled,
    showRangePrice,
    getProductsInfoByIds: getProductsInfoByIds,
  };
}

export default useMenu;

/**
 * @param {string} logo
 * @returns Menu logo path
 */
function resolveMenuLogo(logo) {
  let tmp = '/assets/icon/default-menu-logo.svg';
  if (logo) {
    tmp = logo;
    if (tmp && !tmp.startsWith('http')) {
      tmp = MEDIA_BASE_URL + tmp;
    }
  }
  return tmp;
}

/**
 *
 * @param {string} favicon
 * @returns
 */
const resolveMenuFavicon = (favicon) => {
  let tmp = '/favicon.icon';
  if (favicon) {
    tmp = favicon;
    if (tmp && !tmp.startsWith('http')) {
      tmp = MEDIA_BASE_URL + tmp;
    }
  }
  return tmp;
};

/**
 *
 * @param {string} background
 * @returns
 */
const resolveMenuBackground = (background) => {
  let tmp = '/assets/icon/default-menu-backgound.png';
  if (background) {
    tmp = background;
    if (tmp && !tmp.startsWith('http')) {
      tmp = MEDIA_BASE_URL + tmp;
    }
  }
  return tmp;
};

/**
 * Resolve show local switch based on language and currency setup
 * @param {*} supported_lang
 * @param {*} supported_currency
 * @returns True if menu have more than one language or have more than one currency. Otherwise return false
 */
const resolveShowLocalSwitch = (supported_lang, supported_currency) => {
  let showLocalSwitch = false;

  if (supported_lang?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  if (supported_currency?.split(',').length > 1) {
    showLocalSwitch = true;
  }
  return showLocalSwitch;
};
