import { useSelector } from 'react-redux';

function useDesign() {
  // Access the Redux state
  const qrocde = useSelector((state) => state.qrcode);

  const settings = {};

  if (qrocde && qrocde.settings) {
    settings.categoryListType = qrocde.settings.categoryListType || 'ListView';
    settings.categoryNavigationType = qrocde.settings.categoryNavigationType || 'TEXT';
    settings.displaySelectedCategoryNameEnabled = qrocde.settings.displaySelectedCategoryNameEnabled === 'true';
    settings.productCardVersion = parseInt(qrocde.settings.productCardVersion) || 1;
    settings.menuTemplate = qrocde.settings.menuTemplate || 'SinglePageLayout';
  }

  return {
    ...settings,
  };
}

export default useDesign;
