import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import useDesign from '../../hooks/useDesign';
import ProductCardV1 from './ProductCardV1';
import ProductCardV2 from './ProductCardV2';

function ProductCard(props) {
  const { product } = props;
  const { productCardVersion } = useDesign();

  const renderProductCard = () => {
    if (productCardVersion === 2) {
      return <ProductCardV2 product={product} pdpEnabled={props.pdpEnabled} />;
    } else {
      return <ProductCardV1 product={product} pdpEnabled={props.pdpEnabled} />;
    }
  };

  return (
    <div
      role="listitem"
      className={'product-card product-card-v' + productCardVersion}
      product-id={product.id}
      product-partnumber={product.partnumber}>
      {renderProductCard()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
