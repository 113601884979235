import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import UtilityService from '../services/utility';
import ProductCard from './productCard';
import useMenu from '../hooks/useMenu';

function MenuGroup(props) {
  const { selectedCategory, dineInExtraCostEnabled } = useMenu();
  const layout = props.config.productLayout;
  const productList = props.menu.products;

  const isCustomCategory = selectedCategory && selectedCategory.isCustom === '1';

  const sortProducts = (a, b) => {
    const productSort = props.config.productSort;
    if (productSort === 'sequence') {
      return parseFloat(a.sequence) - parseFloat(b.sequence);
    } else if (productSort === 'rate') {
      return parseFloat(a.product.rate) - parseFloat(b.product.rate);
    } else if (productSort === 'price') {
      return parseFloat(a.product.offer?.priceList[0].price) - parseFloat(b.product.offer?.priceList[0].price);
    } else if (productSort === 'name') {
      if (a.product?.name < b.product?.name) return -1;
      if (a.product?.name > b.product?.name) return 1;
      return 0;
    }
  };

  return (
    <>
      {isCustomCategory && (
        <div className="menu-group-list">
          <div dangerouslySetInnerHTML={{ __html: selectedCategory.htmlContent }} />
        </div>
      )}

      {!isCustomCategory && (
        <div className="menu-group-list">
          {selectedCategory &&
            selectedCategory.groups &&
            selectedCategory.groups
              .sort((a, b) => a.sequence - b.sequence)
              .map((group) => {
                let products = productList.filter((p) => group.items.indexOf(p.id) != -1);
                const dineInOrTakeaway = props.config.dineInOrTakeaway;

                if (!!dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
                  products = products.filter((p) => UtilityService.isAllowToDineIn(p));
                }
                return (
                  <div className="menu-group" key={group.id} data-group-id={group.id}>
                    <div className="group-title">{group.name}</div>
                    {group.description && <div className="group-description">{group.description}</div>}
                    <div className="menu-group-items">
                      <div className={'items-list ' + layout + '-layout'}>
                        {products &&
                          products.sort(sortProducts).map((product) => {
                            return (
                              <ProductCard
                                pdpEnabled={props.pdpEnabled}
                                key={product.id}
                                product={product}
                                allowToOrder={props.menu?.settings?.ALLOW_TO_ORDER === 'true'}
                                rateEnabled={props.menu?.settings?.RATE_ENABLED === 'true'}></ProductCard>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuGroup);
