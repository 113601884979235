import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import ProductImage from './../ProductImage';
import ProductPrice from './../ProductPrice';

function ProductCardV2(props) {
  const { product } = props;

  const renderDescription = (description) => {
    const htmlPattern = /<\/?[a-z][\s\S]*>/i; // A simple regex to check for HTML tags
    if (htmlPattern.test(description)) {
      return <div dangerouslySetInnerHTML={{ __html: description }}></div>;
    }
    return <p>{description}</p>;
  };

  return (
    <div className="product-container">
      <ProductImage product={product} enablePDPLink={false} />

      <div className="product-details">
        <div className="product-name">
          <p className="product-name">{product?.name}</p>
        </div>
        {product && product.description && <div className="product-description">{renderDescription(product.description)}</div>}

        <div className="product-price">
          <ProductPrice product={product} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardV2);
