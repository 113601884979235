import { default as React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage, ProductListPage, ProductDetailsPage } from '../template/TwoPageLayout';

function TwoPageLayout() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="category/:categoryId" element={<ProductListPage />} />
      <Route path="product/:productId" element={<ProductDetailsPage />} />
    </Routes>
  );
}

export default TwoPageLayout;
