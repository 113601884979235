import React from 'react';
import useMenu from '../../hooks/useMenu';
import { Product } from './Product';

const Group = ({ group }) => {
  const { getProductsInfoByIds } = useMenu();

  const products = getProductsInfoByIds(group.items);

  return (
    <div className="category-group" group-id={group.id}>
      <div className="category-group-details">
        <img src={group.thumbnail} alt={group.name} />
      </div>

      <div className="product-list">
        {products.map((product, index) => (
          <Product key={index} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Group;
