import React from 'react';
import { connect } from 'react-redux';
import FormatPrice from './FormatPrice';
import UtilityService from '../services/utility';
import useMenu from '../hooks/useMenu';

function ProductPrice(props) {
  const currency = props.config.currentCurrency;
  const product = props.product;
  const { showRangePrice, dineInExtraCostEnabled } = useMenu();

  const dineInOrTakeaway = props.config.dineInOrTakeaway;

  let minPrice = 0;
  let maxPrice = 0;

  const getExtraCost = (catentry) => {
    if (dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
      for (let index = 0; index < catentry.attribute.length; index++) {
        const attr = catentry.attribute[index];
        if (attr.identifier === 'DINE_IN_EXTRA_COST_' + currency.id) {
          if (attr.value) {
            return parseFloat(attr.value.value || 0);
          }
        }
      }
    }
    return 0;
  };

  if (product && product.type === 'SIMPLE_PRODUCT') {
    const extraCost = getExtraCost(product);
    minPrice = parseFloat(product['price_' + currency.id] || 0);
    minPrice = minPrice + extraCost;
    maxPrice = minPrice;
  } else if (product && product.type === 'COMPLEX_PRODUCT') {
    if (product.items) {
      product.items.forEach((item) => {
        if (dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN' && !UtilityService.isAllowToDineIn(item)) {
          return;
        }
        const itemPrice = item['price_' + currency.id];
        const extraCost = getExtraCost(item);

        const price = parseFloat(itemPrice || 0) + extraCost;

        if (minPrice === 0 || price < minPrice) {
          minPrice = price;
        }
        if (maxPrice === 0 || price > maxPrice) {
          maxPrice = price;
        }
      });
    }
  }

  const RenderProductPrice = () => {
    if (showRangePrice && minPrice < maxPrice) {
      return (
        <div className="product-range-price">
          <FormatPrice value={minPrice} /> - <FormatPrice value={maxPrice} />
        </div>
      );
    } else {
      return <FormatPrice value={minPrice} />;
    }
  };

  const RenderItemPrice = () => {
    let price = 0;
    if (product && product.type === 'SIMPLE_PRODUCT') {
      const extraCost = getExtraCost(product);
      price = parseFloat(product['price_' + currency.id] || 0);
    } else if (product && product.type === 'COMPLEX_PRODUCT') {
      product.items.forEach((item) => {
        if (parseInt(item.id) === parseInt(props.selectedItemId)) {
          const extraCost = getExtraCost(item);
          price = parseFloat(item['price_' + currency.id] || 0) + extraCost;
        }
      });
    }
    return <FormatPrice value={price} />;
  };

  return (
    <>
      {props.selectedItemId && <RenderItemPrice />}
      {!props.selectedItemId && <RenderProductPrice />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    qrcode: state.qrcode,
    config: state.config,
    menu: state.menu,
  };
}

export default connect(mapStateToProps)(ProductPrice);
