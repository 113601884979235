import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setConfig } from '../actions/configActions';
import { setMenu, setProductsMap } from '../actions/menuActions';
import { fetchQRCode } from '../actions/qrcodeActions';
import { fetchShoppingCartDetails } from '../actions/shoppingCartActions';
import useCart from '../hooks/useCart';
import useDesign from '../hooks/useDesign';
import MenuService from '../services/menu';
import UtilityService from '../services/utility';
import { SUPPORTED_LAYOUT, SUPPORTED_SORT } from '../utils/constant';
import { getCurrency, getCurrentCurrency, getCurrentLanguage, getLanguage } from '../utils/utility';
import CommonLayout from './CommonLayout';
import Menu19Layout from './Menu19Lauout';
import MexicanRollPageLayout from './MexicanRollPageLayout';
import SinglePageLayout from './SinglePageLayout';
import TwoPageLayout from './TwoPageLayout';
import UncleOsakaLayout from './UncleOsakaLayout';
import MastekAnanasLayout from './MastekAnanasLayout';

function BaseLayout(props) {
  const { uuid } = useParams();
  const { i18n } = useTranslation();
  const { diningOption } = useCart();
  const navigate = useNavigate();
  const { menuTemplate } = useDesign();

  useEffect(() => {
    if ((props.qrcode == null || props.qrcode.id == null) && uuid != null) {
      props.fetchQRCode(uuid).then((data) => {
        if (data != null && data.menu != null) {
          // 1. resolve language from local storage.
          let fetchLang = getCurrentLanguage(data.menu.id);
          if (fetchLang === null) {
            // if no language in local storage, use ,menu defualt language
            fetchLang = getLanguage(parseInt(data.menu.default_lang));
          }

          // save current language in reducer
          props.setConfig({ currentLanguage: fetchLang });

          // change language in i18n library
          i18n.changeLanguage(fetchLang.code);

          // 2. resolve currnecy from local storage.
          let fetchCurrency = getCurrentCurrency(data.menu.id);

          if (fetchCurrency === null) {
            // if no currency in local storage, use ,menu defualt currency
            fetchCurrency = getCurrency(parseInt(data.menu.default_currency));
          }

          // save current currency in reducer
          props.setConfig({ currentCurrency: fetchCurrency });

          const defaultLayout = data.settings?.DEFAULT_LAYOUT || null;
          const defaultSort = data?.settings?.DEFAULT_SORT || null;

          // set default layout & sort
          if (SUPPORTED_LAYOUT.indexOf(defaultLayout) !== -1) {
            props.setConfig({ productLayout: defaultLayout });
          } else {
            props.setConfig({ productLayout: SUPPORTED_LAYOUT[0] });
          }

          if (defaultSort) {
            props.setConfig({ productSort: defaultSort });
          } else {
            props.setConfig({ productSort: SUPPORTED_SORT[0].value });
          }

          MenuService.getMenuJSON(data.menu.id, fetchLang.id, data.menu.version)
            .then((res) => res.json())
            .then((menuData) => {
              if (menuData) {
                // set default selected category
                if (menuData.categories != null && menuData.categories.length > 0) {
                  menuData.selectedCategory = menuData.categories[0].id;
                }

                // save menu in reducer
                props.setMenu(menuData);

                // build product map
                // TODO: NO LONGER NEED THIS CODE
                props.setProductsMap(UtilityService.buildProductMap(menuData));
              }
            });

          // get diningOption from session
          if (diningOption === 'DINE_IN' || diningOption === 'TAKE_AWAY') {
            props.setConfig({ dineInOrTakeaway: diningOption });
          }

          // fetch shopping cart data
          props.fetchShoppingCartDetails(data.id);

          props.setConfig({ dineInOrTakeaway: diningOption });

          MenuService.sendTrackEvent('MENU_VIEW', { menuId: data.menu.id });
        } else {
          // invalid uuid
          navigate('/not-found');
        }
      });
    }
  }, [navigate, uuid, props, i18n]);

  /**
   * Will fetch data from api, then use the configured layout or the default
   */
  return (
    <CommonLayout>
      {menuTemplate === 'SinglePageLayout' && <SinglePageLayout />}
      {menuTemplate === 'TwoPageLayout' && <TwoPageLayout />}
      {menuTemplate === 'UncleOsakaLayout' && <UncleOsakaLayout />}
      {menuTemplate === 'Menu19Layout' && <Menu19Layout />}
      {menuTemplate === 'MexicanRollLayout' && <MexicanRollPageLayout />}
      {menuTemplate === 'MastekAnanasLayout' && <MastekAnanasLayout />}
      {menuTemplate === null && <SinglePageLayout />}
    </CommonLayout>
  );
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    qrcode: state.qrcode,
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchQRCode: fetchQRCode,
      fetchShoppingCartDetails: fetchShoppingCartDetails,
      setConfig: setConfig,
      setMenu: setMenu,
      setProductsMap: setProductsMap,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
