import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MenuGroup from '../../components/MenuGroup';
import CategoryNavigation from '../../components/CategoryNavigation';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';

function SinglePageLayoutTemplate() {
  const { uuid } = useQRCode();
  const { supported_lang, supported_currency, logoURL } = useMenu();

  let showLocalSwitch = false;

  if (supported_lang?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  if (supported_currency?.split(',').length > 1) {
    showLocalSwitch = true;
  }

  return (
    <div className="single-page-layout main-card">
      <Header
        showBackButton={false}
        showShareButton={true}
        showLocalSwitch={showLocalSwitch}
        showBusinessDetails={false}
        backURL={'/m/' + uuid}
      />
      <div className="body">
        <div className="menu-logo">
          <img alt="" src={logoURL} />
        </div>
        <CategoryNavigation />

        <MenuGroup />
      </div>
      <Footer />
    </div>
  );
}

export default SinglePageLayoutTemplate;
